import React, { useState } from 'react';
import { useCart } from './CartContext';
import emailjs from 'emailjs-com';  // Import EmailJS
import Navbar from './Navbar';
import Footer from './Footer';

const SERVICE_ID = 'service_re647qi';
const TEMPLATE_ID = 'template_jueu7e4';
const PUBLIC_KEY = 'FFOFTA_Dq47I-Yt0_';

function Checkout() {
  const { cart } = useCart();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateTotalPrice = () => {
    return cart.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price.replace('€', '').replace(',', '.'));
      const itemQuantity = item.quantity || 1;
      return acc + (itemPrice * itemQuantity);
    }, 0).toFixed(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const totalPrice = calculateTotalPrice();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      address: formData.address,
      cart_items: cart.map(item => `${item.title} (Qty: ${item.quantity || 1}, Price: ${item.price})`).join(', '),
      total_price: `${totalPrice}€`,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setIsSubmitted(true);
      }, (err) => {
        console.log('Error sending email:', err);
      });
  };

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <div className="container py-3">
          <section className="py-3 py-md-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <h2 className="mb-4">Checkout</h2>
                  {isSubmitted ? (
                    <div className="alert alert-success text-center" role="alert">
                      <h4>Thank you! Your order has been placed, and we will contact you shortly.</h4>
                      <h5>Your Order:</h5>
                      <ul className="list-unstyled">
                        {cart.map((item) => (
                          <li key={item.id}>
                            {item.title} (Qty: {item.quantity || 1}, Price: {item.price})
                          </li>
                        ))}
                      </ul>
                      <h6>Total Price: {calculateTotalPrice()}€</h6>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address</label>
                        <textarea
                          id="address"
                          name="address"
                          className="form-control"
                          rows="3"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">Place Order</button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
