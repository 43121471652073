import React, { useState } from 'react';
import { database } from './Firebase'; // Make sure the path matches where your Firebase.js is located
import { ref, set } from "firebase/database";

function Test() {
  const [number, setNumber] = useState('');

  const handleInputChange = (e) => {
    setNumber(e.target.value);
  };

  const saveNumberToDatabase = () => {
    // Reference to where you want to save the data
    const dbRef = ref(database, 'numbers/entry'); // Adjust the path as needed

    // Save the number to the database
    set(dbRef, {
      value: number
    })
    .then(() => {
      alert('Number saved successfully!');
    })
    .catch((error) => {
      console.error('Error saving number to database:', error);
    });
  };

  return (
    <div>
      <h2>Enter a Number</h2>
      <input 
        type="number" 
        value={number} 
        onChange={handleInputChange} 
        placeholder="Enter a number" 
      />
      <button onClick={saveNumberToDatabase}>Save to Database</button>
    </div>
  );
}

export default Test;
