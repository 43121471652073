// src/Cart.js
import React from 'react';
import { useCart } from './CartContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { FaTrashAlt } from 'react-icons/fa';

function Cart() {
  const { cart, removeFromCart, updateQuantity } = useCart();
  const navigate = useNavigate(); // Initialize useNavigate

  const parsePrice = (price) => {
    const parsedPrice = parseFloat(price.replace('€', '').replace(',', '.'));
    console.log(`Parsed Price for ${price}:`, parsedPrice);
    return parsedPrice;
  };

  const totalPrice = cart.reduce((acc, item) => {
    const itemPrice = parsePrice(item.price);
    const itemQuantity = item.quantity || 1;
    console.log(`Item Price: ${itemPrice}, Quantity: ${itemQuantity}`);
    return acc + (itemPrice * itemQuantity);
  }, 0).toFixed(2);

  console.log('Total Price:', totalPrice);

  const handleQuantityChange = (id, quantity) => {
    const newQuantity = Math.max(1, parseInt(quantity, 10));
    updateQuantity(id, newQuantity);
  };

  const handleCheckout = () => {
    navigate('/checkout'); // Navigate to the checkout page
  };

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <div className="container py-3">
          <section className="py-3 py-md-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
                  <div className="card widget-card border-light shadow-sm">
                    <div className="card-body p-4">
                      <h5 className="card-title widget-card-title mb-4">Your Cart</h5>
                      <div className="row gy-4">
                        {cart.map(item => (
                          <div className="col-12" key={item.id}>
                            <div className="row align-items-center">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <div className="me-3">
                                    <img src={item.imgSrc} alt={item.altText} className="img-thumbnail" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                  </div>
                                  <div>
                                    <h6 className="m-0">{item.title}</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 mb-1">
                                <h6 className="text-end">{item.price}</h6>
                              </div>
                              <div className="col-12 d-flex align-items-center">
                                <div className="me-3" style={{ width: '80px' }}>
                                  <input 
                                    type="number" 
                                    min="1" 
                                    value={item.quantity || 1}
                                    onChange={(e) => handleQuantityChange(item.id, e.target.value)} 
                                    className="form-control form-control-sm text-center" 
                                  />
                                </div>
                                <button className="btn btn-light btn-sm text-danger" onClick={() => removeFromCart(item.id)}>
                                  <FaTrashAlt />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="col-12 mt-3">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="fs-5 bsb-w-50 bsb-h-50 bg-primary-subtle text-primary rounded-2 d-flex align-items-center justify-content-center me-3">
                                    <i className="bi bi-cart"></i>
                                  </div>
                                </div>
                                <div>
                                  <h6 className="m-0">Total</h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 mb-1">
                              <h6 className="text-end">{totalPrice}€</h6>
                            </div>
                          </div>
                        </div>
                        <button className='btn btn-primary btn-sm' onClick={handleCheckout}>Checkout</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cart;
