import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import openblue from './Fontana-logo-3.png';
import ReactCountryFlag from "react-country-flag";
import { useCart } from './CartContext';

function Navbar() {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState("GB"); // Default to GB flag
  const { cart } = useCart();

  const items = ['home', 'contact', 'about', 'jobboard', 'suppliers', 'ourclients'];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 1) {
      const filteredSuggestions = items.filter((item) =>
        item.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
    navigate('/' + suggestion);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (items.includes(query.toLowerCase())) {
      navigate('/' + query.toLowerCase());
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // Default to English
    setSelectedLanguage(savedLanguage === 'en' ? 'GB' : 'ES');
  }, []);

  const handleLanguageChange = (language) => {
    const langCode = language === "GB" ? 'en' : 'es'; 
    localStorage.setItem('language', langCode);
    setSelectedLanguage(language);
    window.location.reload(); // Reload to apply language change
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom border-dark-subtle">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={openblue} alt="Logo" width="45" height="24" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* General dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                General
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/contact">Contact</Link></li>
                <li><Link className="dropdown-item" to="/jobboard">Job Board</Link></li>
                <li><Link className="dropdown-item" to="/about">About Us</Link></li>
                <li><Link className="dropdown-item" to="/ourclients">Our Clients</Link></li>
                <li><Link className="dropdown-item" to="/suppliers">Suppliers</Link></li>
              </ul>
            </li>

            {/* Laundry dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Laundry
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/inquiryform">Inquiry Form</Link></li>
                <li><Link className="dropdown-item" to="/quoterequestform">Quote Request Form</Link></li>
                <li><Link className="dropdown-item" to="/informationonprovidedservices">Information On Provided Services</Link></li>
              </ul>
            </li>

            {/* Products dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Products
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/productspage">Inquiry of products and services</Link></li>
                <li><Link className="dropdown-item" to="/cart">Shopping Cart</Link></li>
              </ul>
            </li>

            {/* Co-Hosts Area dropdown */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Co-Hosts Area
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/rentals">Rentals</Link></li>
                <li><Link className="dropdown-item" to="/reservationform">Inquiry and Reservation Form</Link></li>
              </ul>
            </li>

            {/* Private Area link */}
            <li className="nav-item">
              <a className="nav-link" href="#">Private Area</a>
            </li>
          </ul>

          {/* Cart button (moved outside the ul to prevent alignment issues) */}
          <div className="cart-button-container me-3">
            <Link to="/cart" className="btn btn-light cart-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
              </svg>
              {cart.length > 0 && <span className="cart-badge">{cart.length}</span>}
            </Link>
          </div>

          {/* Language selector */}
          <div className="dropdown me-3 language-selector">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <ReactCountryFlag countryCode={selectedLanguage} svg size={23} />
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
              <li>
                <button className="dropdown-item" onClick={() => handleLanguageChange("GB")}>
                  <ReactCountryFlag countryCode="GB" svg style={{ marginRight: '8px' }} /> English
                </button>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => handleLanguageChange("ES")}>
                  <ReactCountryFlag countryCode="ES" svg style={{ marginRight: '8px' }} /> Español
                </button>
              </li>
            </ul>
          </div>

          {/* Search bar */}
          <form className="d-flex" onSubmit={handleSubmit}>
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" value={query} onChange={handleInputChange} />
            <button className="btn btn-outline-success" type="submit">Search</button>
          </form>

          {/* Suggestions list */}
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li key={index} onClick={() => handleSuggestionClick(suggestion)} className="suggestion-item">
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
