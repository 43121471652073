// src/firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAuPE3jeaLSOhtWGE2DqP-Sq_qtjvphP44",
  authDomain: "lavanderia-1bb28.firebaseapp.com",
  databaseURL: "https://lavanderia-1bb28-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lavanderia-1bb28",
  storageBucket: "lavanderia-1bb28.appspot.com",
  messagingSenderId: "96119618667",
  appId: "1:96119618667:web:281802ef1cc498fc7fc52e",
  measurementId: "G-JYXYD11DSG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const database = getDatabase(app);

// Initialize Auth
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { app, database, auth, provider };
