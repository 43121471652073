// src/HomePage.js
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function OurClients() {
  const [language, setLanguage] = useState('en');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // default to 'en'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="fs-6 text-secondary mb-2 text-uppercase text-center">{translations.clientsTitle}</h2>
                <h2 className="mb-4 display-5 text-center">{translations.welcomeMessage}</h2>
                <p className="fs-5 text-secondary mb-5 text-center">{translations.priorityMessage}</p>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
              </div>
            </div>
          </div>

          <div className="container overflow-hidden">
            <div className="row gy-4">
              {[...Array(12)].map((_, index) => (
                <div className="col-6 col-md-4 col-xl-3 text-center" key={index}>
                  <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default OurClients;
