import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import { database } from './Firebase';
import { ref, onValue } from 'firebase/database';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css'; // Ensure this file contains the CSS rules for images

function ProductsPage() {
  const { cart, addToCart, isInCart } = useCart(); // Ensure isInCart is imported correctly
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const productsRef = ref(database, 'products');

    const fetchData = () => {
      onValue(productsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setProducts(Object.keys(data).map(key => ({
            id: key,
            ...data[key]
          })));
        }
        setLoading(false);
      }, {
        onlyOnce: true
      });
    };

    fetchData();
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <div className='container'>
          <section className="py-3 py-md-5">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                  <h2 className="mb-4 display-5 text-center">Nuestros Productos</h2>
                  <p className="text-secondary mb-5 text-center lead fs-4">
                    Ofrecemos un servicio de lavado de alta calidad para toallas, sábanas y otros artículos del hogar similares, garantizando que queden limpios y frescos como nuevos.
                  </p>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                </div>
              </div>
            </div>

            <div className="container overflow-hidden">
              <div className="row gy-4 gy-xxl-5">
                {loading ? (
                  <p className="text-center">Loading...</p>
                ) : products.length === 0 ? (
                  <p className="text-center">No products available</p>
                ) : (
                  products.map((product) => ( // Use 'product' for each item in products
                    <div className="col-12 col-md-6 col-lg-4 d-flex" key={product.id}>
                      <article className="d-flex">
                        <div 
                          className="card1 border border-secondary" 
                          style={{ '--bs-card-border-radius': '0', '--bs-card-inner-border-radius': '0' }}
                        >
                          <figure className="card-img-top m-0 overflow-hidden bsb-overlay-hover">
                            <a href="#!" style={{ textDecoration: 'none' }}>
                              <img
                                className="img-fluid bsb-scale bsb-hover-scale-up"
                                loading="lazy"
                                src={product.imgSrc}
                                alt={product.altText}
                              />
                            </a>
                            <figcaption>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-eye text-white bsb-hover-fadeInRight"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                              <h4 className="h6 text-white bsb-hover-fadeInRight mt-2">Read More</h4>
                            </figcaption>
                          </figure>
                          <div className="card-body border-0 bg-white p-4">
                            <div className="entry-header mb-3">
                              <ul className="entry-meta list-unstyled d-flex mb-2">
                                <li>
                                  <a className="link-dark link-opacity-75-hover" href="#!" style={{ textDecoration: 'none' }}>
                                    <i className="bi bi-calendar-date fs-5"></i>
                                    <span className="ms-2">{product.date}</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h4 className="card-title mb-2">
                              <a className="text-dark" href="#!" style={{ textDecoration: 'none' }}>{product.title}</a>
                            </h4>
                            <p className="card-text mb-4">{product.description}</p>
                            <p className="card-price mb-4" style={{ fontWeight: 'bold', color: '#333' }}>{product.price}</p>

                            <button
                              onClick={() => addToCart(product)} // Correctly passing 'product' to addToCart
                              disabled={isInCart(product.id)} // Disable button if item is in cart
                              className={`btn ${isInCart(product.id) ? 'btn-success' : 'btn-outline-dark'} btn-sm`} 
                            >
                              {isInCart(product.id) ? 'In Cart' : 'Add to Cart'}
                            </button>

                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ProductsPage;
