// src/HomePage.js
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css';
import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

function JobBoard() {
  const [language, setLanguage] = useState('en');
  const [translations, setTranslations] = useState(enTranslations);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // default to 'en'
    setLanguage(savedLanguage);
    setTranslations(savedLanguage === 'en' ? enTranslations : esTranslations);
  }, []);

  const sendEmail = () => {
    window.location.href = "mailto:test@gmail.com";
  };

  return (
    <div>
      <Navbar />
      <div className='App-Header'>
        <section className=" py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="mb-4 display-5 text-center">{translations.welcomeMessage}</h2>
                <p className="text-secondary mb-5 text-center lead fs-4">{translations.subHeader}</p>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row gy-3 gy-md-4">
                    {/* Job 1 */}
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="card border-dark">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="text-primary mb-4" viewBox="0 0 16 16">
                            <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a1 1 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207" />
                            <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                          </svg>
                          <h4 className="mb-4">{translations.attendantTitle}</h4>
                          <p className="mb-4 text-secondary">{translations.attendantDescription}</p>
                          <button className="btn btn-outline-primary bsb-btn-circle" onClick={sendEmail}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Add more job cards here, just like the one above */}
                    {/* Example: Manager */}
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="card border-dark">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-person-workspace text-primary mb-4" viewBox="0 0 16 16">
                            <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                            <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.4 5.4 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2z" />
                          </svg>
                          <h4 className="mb-4">{translations.managerTitle}</h4>
                          <p className="mb-4 text-secondary">{translations.managerDescription}</p>
                          <a href="#!" className="btn btn-outline-primary bsb-btn-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                              <path
                                fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
  <div class="card border-dark">
    <div class="card-body text-center p-4 p-xxl-5">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-person-fill-gear text-primary mb-4" viewBox="0 0 16 16">
        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4m9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
      </svg>
      <h4 class="mb-4">{translations.maintenanceTechnicianTitle}</h4>
      <p class="mb-4 text-secondary">{translations.maintenanceTechnicianDescription}</p>
      <a href="#!" class="btn btn-outline-primary bsb-btn-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
        </svg>
      </a>
    </div>
  </div>
</div>

                    {/* Continue adding more job cards similarly */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default JobBoard;
