// src/RentalsPage.js
import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import './App.css'

function Rentals() {
  return (
    <div>
      <Navbar />
    <div className='App-Header'>
    <h2 className="display-5 text-center  py-md-2 ">Fontana</h2>
    <p className="text-secondary mb-2 text-center lead fs-4">Welcome to Fontana Rentals!</p>      {/* Add your Rentals page content here */}
    <div className='container text-center'>
    <a className="text-secondary mb-2 text-center lead fs-4 text-center" href='https://www.idealista.com/alquiler-viviendas/madrid/usera/'>Click here to view Fontana Rentals!</a>      {/* Add your Rentals page content here */}

    </div>

    </div>
      <Footer />
    </div>
  );
}

export default Rentals;
