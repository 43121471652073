// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Contact from './Contact';
import Error from './Error';
import Bot from './Bot';
import About from './About';
import JobBoard from './JobBoard';
import Suppliers from './Suppliers';
import OurClients from './OurClients';
import Rentals from './Rentals';
import ReservationForm from './ReservationForm';
import InquiryForm from './InquiryForm';
import FAQ from './FAQ';
import InformationOnProvidedServices from './InformationOnProvidedServices';
import QuoteRequestForm from './QuoteRequestForm';
import ProductsPage from './ProductsPage';
import Login from './login';
import Test from './Test';
import Cart from './Cart';
import { CartProvider } from './CartContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import Checkout from './Checkout';
import ScrollToTop from './ScrollToTop';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <CartProvider>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} /> {/* Redirect all other paths to the home page */}
          <Route path="/*" element={<Error />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/bot" element={<Bot />} />
          <Route path="/jobboard" element={<JobBoard />} />
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/ourclients" element={<OurClients />} />
          <Route path="/rentals" element={<Rentals />} />
          <Route path="/reservationform" element={<ReservationForm />} />
          <Route path="/inquiryform" element={<InquiryForm />} />
          <Route path="/quoterequestform" element={<QuoteRequestForm />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/login" element={<Login />} />
          <Route path="/checkout" element={<Checkout />} />

          <Route path="/test" element={<Test />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/informationonprovidedservices" element={<InformationOnProvidedServices />} />
          <Route path="/productspage" element={<ProductsPage />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
